import react, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import { message } from "antd";

import "./index.css";

import CarouselImageSrc1 from "assets/images/home/carousel1.png";
import CarouselImageSrc2 from "assets/images/home/carousel2.png";
import history from "assets/images/home/history.png";
import history_en from "assets/images/home/history_en.png";

export const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover`}
`;

const title = tw`text-3xl sm:text-5xl lg:text-6xl`;
const title_en = tw`text-2xl sm:text-4xl lg:text-5xl tracking-normal`;

const detail = tw`text-xs sm:text-sm lg:text-lg`;

export default () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {}, []);

  const handleLinkAboutEnco = () => {
    navigate("/aboutEnco/R&Dteam");
  };

  const imgList = [CarouselImageSrc1, CarouselImageSrc2];

  const handleSlideChange = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const currentLanguage = localStorage.getItem("currentLanguage");

  return (
    <Container>
      {contextHolder}
      <Carousel fade activeIndex={activeIndex} onSelect={handleSlideChange}>
        {imgList.map((item, index) => (
          <Carousel.Item key={index} className="carouselItem">
            <img
              className={`d-block w-100 img ${
                index === activeIndex ? "imgActive" : ""
              }`}
              src={item}
              alt={`享刻智能首页 第${index + 1}张轮播图`}
            />
            <Carousel.Caption className="carouselCaption">
              <h1
                className="title"
                css={currentLanguage == "zh" ? title : title_en}
              >
                {t("AboutEnco")}
              </h1>
              <button
                className={currentLanguage == "zh" ? "btn" : "btn_en"}
                onClick={handleLinkAboutEnco}
              >
                {t("LearnMore")}
              </button>
              <p className="detail" css={detail}>
                {t("CarouselDesc")}
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <img
        src={currentLanguage == "zh" ? history : history_en}
        alt="享刻智能的发展路线"
      />
    </Container>
  );
};
