// import react from "react";
import investor1 from 'assets/images/investors/investor1.png'
import investor2 from 'assets/images/investors/investor2.png'
import investor3 from 'assets/images/investors/investor3.png'
import investor4 from 'assets/images/investors/investor4.png'
import investor5 from 'assets/images/investors/investor5.png'
import investor6 from 'assets/images/investors/investor6.png'

import "./index.css";
import { useTranslation } from 'react-i18next'

export default () => {
    const { t } = useTranslation();

  return (
    <div className="investors_container">
        <div className="title">{t("Investors")}</div>
        <div className="investor_name">
            <div className="img-item">
                <img src={investor1} alt="真格基金" />
            </div>
            <div className="img-item">
                <img src={investor2} alt="九阳" />
            </div>
            <div className="img-item">
                <img src={investor3} alt="雅瑞资本" />
            </div>
            <div className="img-item">
                <img src={investor4} alt="瑞昇投资" />
            </div>
            <div className="img-item">
                <img src={investor5} alt="Decent capital" />
            </div>
            <div className="img-item">
                <img src={investor6} alt="振邦智能" /> 
            </div>
        </div>
    </div>
  );
};