const TRANSLATION_EN = {
    "EncoSmart": "EncoSmart",

    // header 头部
    "AboutEnco": "About EncoSmart",
    "Product": "Products",
    "PressReleases": "Newsroom",
    "CoreTechnology": "Core Technology",
    "CleaningTools": "Cleaning Tools",
    "ContactUs": "Contact Us",

    // footer 尾部
    "BusinessCooperation": "Business Cooperation",
    "Recruitment": "Careers",
    "TeaWorkstation": "Tea Workstation",
    "R&Dteam": "R&D Team",
    "EncoInformation": "Information",
    "NewsCenter": "News Center",
    "CommonProblem": "FAQ",
    "InvestorCenter": "Investor Center",
    "CopyrightNotice": "© 2023 EncoSmart Technology (Beijing) Ltd. Co. All Rights Reserved.",
    "RecordKeeping": "京ICP备2022017723号-1",

    //首页
    "LearnMore": "Learn More",
    "CarouselDesc": "EncoSmart was established in Beijing in May 2022. We are a leading start-up company in China that focuses on multi-modal perception technology of robots and provides efficient and intelligent full-stack robot products and automation solutions for various life scenarios.",
    "AboutProduct": "About Our Products",
    "AboutProductDesc": "Automatic Frying Robot and Workstation Designed for Fast Food Restaurant",
    "CoreTechnologyDesc": "The core technology and core technical barrier of our product is the integration of the multi-modal robot automation system with the customized execution structure design of the robot, the three-dimensional perception positioning and task planning system, and the traditional commercial automation equipment.",
    "AboutEncoDesc": "EncoSmart is a robotics and intelligent technology start-up company in China, focusing on various life scenarios represented by the catering industry.Through the research and development of software and hardware integrated products centered on multi-modal perception models and embodied intelligence platforms, we provide the most cost-effective unmanned cost-reducing and efficiency-enhancing solutions for the global catering industry.",
    "Founder": "EncoSmart Founder&CEO",
    "FounderDesc": `EncoSmart founder and CEO; professor-level senior engineer; executive member of the CCF Human- Computer Interaction Special Committee of the China Computer Federation; Senior researcher of Beihang University Hangzhou Innovation Institute; member of the Entrepreneurs Association of Tsinghua University; former founder and CEO of QfeelTech. Owns more than 70 Chinese and overseas patents; published 8 academic papers, was selected as Zhongguancun "High-end Talent Gathering Project Plan" Innovation High-end Leading Talents; Zhongguancun Innovation Partner, Beijing Young Talent; received the Special Prize of the Innovation and Entrepreneurship Scholarship from the Ministry of Industry and Information Technology; won the second place in the Internet plus Entrepreneurship Competition of the Ministry of Education; was selected by Forbes as one of the 30 outstanding elites in Asia under the age of 30 who have changed the world. In 2021, Chen Zhen established the "Chen Zhen Innovation and Entrepreneurship" scholarship at Beihang University to assist Beihang in cultivating innovative and entrepreneurial talents.`,
    "LatestNews": "Latest News",
    "PastNews": "Past News",
    "News1Info": "2023 EncoSmart Summer Recruitment｜We’re hiring! Join us to create AI+ Robots together",
    "News2Info": "Good news｜EncoSmart Chen Zhen was nominated for the 2023 World Artificial Intelligence Conference (WAIC) Industry Innovation Top Ten Outstanding Figures",
    "News3Info": "Topic sharing | EncoSmart Chen Zhen: In the next ten years, technological innovators based in China should convey Chinese values to the world. ",
    "VideoDesc": "Unlock more possibilities in Robotic Kitchen",
    "VideoError": "Your browser does not support the video tag.",
    "Investors": "Investors",

    // Lava产品页
    "LavaHeaderDescInfo": "EncoSmart LAVA is an intelligent frying product solution based on technical innovation of frying scene. ",
    "LavaHeaderDescDetail": "This product integrates self-developed vision module and customized vertical field robotic arm, and realizes efficient, intelligent and precise production capacity of fried products through advanced technologies such as image processing, motion planning and automatic control. The vision module can identify the shape and state of the ingredients with high precision, and the customized robotic arm can efficiently complete operations such as retrieving, cooking, and discharging, realizing fully automated production. The advanced image processing algorithm and intelligent motion planning system ensure that the robot can control the movement flexibly and efficiently. The automatic control system ensures the whole process of intelligent production, reduces human intervention, and ensures food quality and safety. By recording production data and quality indicators in real time, the robot provides data management and traceability capabilities, helping enterprises to carry out refined operation management. LAVA brings technological breakthroughs and opportunities for sustainable development to the catering industry.",
    "LavaScenarioTitle": "Application Scenario",
    "LavaScenarioDesc": "Fast food chain restaurants, buffets, large canteens and other Western-style dining places",
    "LavaScenario1": "Fast food Chain Restaurant",
    "LavaScenario2": "Buffet",
    "LavaScenario3": "Large Canteen",
    "LavaProductTitle": "Product Introduction",
    "LavaProductDesc": "Fully Automatic Frying Robot Workstation for Chain Fast Food Restaurants",
    "LavaProductEncoJoint25Desc": "EncoJoint 25 is an integrated, high-performance, low-energy robot core joint module, which integrates drivers, torque motors, optical encoders, friction brakes, harmonic reducers, and a highly integrated precision structure design. The joint module can work with other modules or systems to achieve multi-axis motion control. Through precise position synchronization and coordination, complex multi-axis motion tasks can be realized, increasing production efficiency and system flexibility.",
    "LavaProductEncoArmDesc": "EncoArm is a collaborative robot customized for kitchen. It is designed to liberate tedious and inefficient repetitive labor in the kitchen and better adapt to the work environment through collaborative automation. EncoArm has the flexibility and autonomy of software and hardware direct access, and can seamlessly integrate and interact with other devices or systems. EncoArm not only saves space, but also reduces the cost of deploying robots. Its intelligence and high programmability allow it to adapt to various kitchen tasks such as cutting, mixing, carrying and more. The EncoArm's collaborative capabilities allow it to perform tasks alongside human operators, increasing productivity and safety.",
    "LavaProductEncobotLavaDesc1": "Encobot LAVA is an intelligent frying product solution that stands out for its low cost, safety and flexibility. It adopts advanced Al algorithm and space perception technology, and is equipped with independent planning ability to realize efficient material identification and precise operation. ",
    "LavaProductEncobotLavaDesc2": "LAVA's order management system supports multi-order scheduling and time optimization. It can easily handle multiple orders through one-click operation, improve production efficiency, and relieve the pressure of meal peaks. The automatic control function ensures the stability and reliability of the system, and simplifies the maintenance process at the same time, bringing intelligent and efficient production capacity of fried products to restaurants. Its cooking system utilizes multimodal data and a cloud platform to optimize the frying process and improve product quality and taste. Moreover, LAVA is scalable to meet the application scenarios of different kitchen sizes and needs.",
    "LavaProductEncobotLavaDesc3": "Its advantages lie in low cost, safety and flexibility. With the help of technologies such as Al algorithm, space perception, independent planning and food identification, it can realize efficient automatic production and provide stable and reliable fried products solutions for restaurants. At the same time, the multi-order scheduling and time optimization functions of the order management system, as well as the multi-modal data and cloud platform support of the cooking system, have further improved production efficiency and quality control. Whether it is a small cafeteria or a large-scale chain restaurant, LAVA will provide intelligent and efficient fried product production capacity, helping the catering industry to innovate continuously and adapt to the rapidly changing market demand.",
    "LavaFeaturesTitle": "Main Function and Characteristics",
    "LavaFeaturesDesc": "Fully Automatic Frying Robot Workstation for Chain Fast Food Restaurants",
    "LavaFeatures1Title": "Highly Integrated",
    "LavaFeatures1Desc": "Highly Integrated Precision Design, Stronger Performance",
    "LavaFeatures2Title": "Fast And Precise Braking",
    "LavaFeatures2Desc": "Using Friction Brake Brake, Quick Start And Stop Is Safer",
    "LavaFeatures3Title": "High Performance Drive",
    "LavaFeatures3Desc": "High-Performance Drive, Intelligent Flexible And Efficient Drive",
    "LavaFeatures4Title": "High Efficiency Torque Motor",
    "LavaFeatures4Desc": "With high torque resolution and low torque ripple, it can realize more precise position and speed control.",
    "LavaFeatures5Title": "High Precision Double Encoding",
    "LavaFeatures5Desc": "Two independent encoders are used for position feedback to provide more accurate absolute position information, thereby realizing full-closed-loop precise control.",
    "LavaProductVideo": "Product Video",
    "LavaMoreProductInfo": "More Product Information",

    // 联系我们
    "ContactUsTitle": "Contact us for product information",
    "FormName": "Your name",
    "FormEmail": "Your business email",
    "FormCompany": "Your company",
    "FormPhone": "Your phone number",
    "InterestedProduct": "Which product are you interested in",
    "SubmitInfo": "Submit",
    "OurEmail": "Our Email",
    "Address": "Address",
    "NoPublic": "Official account",

    // 核心技术
    "CoreTechTitle": "Core Technology",
    "CoreTechDesc": "The core technical point of the product is also the core technical barrier of the product, which is based on the customized execution structure design of the robot, with the three-dimensional perception positioning and task planning system, and the multi-modal robot automation system integration combined with traditional commercial automation equipment.",
     
    // 研发团队
    "TeamHeaderInfoTitle": "Company Vision",
    "TeamHeaderInfoDesc": "The vision of EncoSmart is to become a new representative force in the global robotics industry, empowering the global market with original core technologies and products. Relying on the advantages and foundation of the core team in the short term, it will quickly reach the top level of robot R&D and productization in the industry, realize mass production and landing of 10,000-level products in the medium term, and become a global representative of original core robot technology and product companies in the long run.",
    "TeamHeaderDetailItem1Title": "The company's core team",
    "TeamHeaderDetailItem1Desc": "The company's core team is led by Mr. Evan Chen, a leading talent in the robotics industry. He has more than 10 years of R&D and entrepreneurial experience. The main members come from the product and R&D departments of many global technology groups and listed kitchen appliances companies. They have participated in a number of global robot products and millions of products. Level hardware mass production.",
    "TeamHeaderDetailItem2Title": "Partner Organization",
    "TeamHeaderDetailItem2Desc": "The company maintains close cooperation with robotics laboratories and research institutions including top well-known universities, and has obtained tens of millions of yuan in financing from a number of well-known early-stage venture capital institutions and listed companies. It is currently in a stage of rapid growth.",
    "TeamHeaderDetailItem3Title": "EncoSmart",
    "TeamHeaderDetailItem3Desc": "EncoSmart is a robot and intelligent technology start-up company, focusing on pan-life service scenarios represented by the catering industry. Through the research and development of software and hardware integrated products with multi-modal perception models and embodied intelligence platforms as the core, the company provides the most cost-effective unmanned cost-reducing and efficiency-enhancing solutions for chain brands such as the global catering industry.",
    "TeamCoreTeamTitle": "Core Team",
    "TeamCompanyMembers": "Members",
};

export default TRANSLATION_EN;