import "./index.css";
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useNavigate, } from 'react-router-dom';
import News from 'components/news/index';

import news7 from "assets/images/encoInfo/newsCenter/news7.png";
import news6 from "assets/images/encoInfo/newsCenter/news6.png";
import news5 from "assets/images/encoInfo/newsCenter/news5.png";

export default () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const newsImg = [
    {
      img: news7,
      date: "Jul. 14, 2023",
      msg: "2023享刻智能夏季招聘｜诚聘英才，邀你一起共创AI+机器人",
      link: "https://mp.weixin.qq.com/s/_48y3XcXqDyhskfms09HSg",
    },
    {
      img: news6,
      date: "Jul. 07, 2023",
      msg: "喜报｜享刻智能陈震获2023世界人工智能大会WAIC产业创新十大杰出人物提名",
      link: "https://mp.weixin.qq.com/s/7RkOTtxcfhWlVcCwgh2qMA",
    },
    {
      img: news5,
      date: "May. 22, 2023",
      msg: "享刻智能陈震入选2023年度创业邦30U30创业先锋、36氪Under36创业者双榜单",
      link: "https://mp.weixin.qq.com/s/X6cOY6SOgSZ6z68edgQ-HA",
    },
  ];

  const handleLinkPastNews = () => {
    navigate("/encoInfo/newsCenter")
  }

  return (
    <div className="new_container">
      {contextHolder}
      <div className="content">
        <div className="title">{t("LatestNews")}</div>
        <div className="btn_wrapper">
          <button className="history_btn" onClick={handleLinkPastNews}> {t("PastNews")} {">>"} </button>
        </div>
        <News data={newsImg} />
      </div>
    </div>
  );
};