import React from "react";
import tw from "twin.macro";
import { motion } from "framer-motion";
import useInView from "helpers/useInView";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const directions = ["left", "right"];
  const childrenWithAnimation = children.map((child, i) => {
    return (
      <AnimatedSlideInComponent key={i} direction={directions[i % directions.length]}>
        {child}
      </AnimatedSlideInComponent>
    );
  });
  return <>{childrenWithAnimation}</>; 
}


function AnimatedSlideInComponent({ direction = "left", offset = 30, children }) {
  const [ref, inView] = useInView({ margin: `-${offset}px 0px 0px 0px`});

  const x = { target: "0%" };

  if (direction === "left") x.initial = "-150%";
  else x.initial = "150%";

  return (
    <div ref={ref}>
      <motion.section
        initial={{ x: x.initial }}
        animate={{ 
          x: inView && x.target,
          transitionEnd:{
            x: inView && 0
          }
        }}
        transition={{ type: "spring", damping: 19 }}
      >
        {children}
      </motion.section>
    </div>
  );
}



// function AnimationReveal({ disabled, children }) {
//   if (disabled) {
//     return <>{children}</>;
//   }

//   if (!Array.isArray(children)) children = [children];

//   const childrenWithAnimation = children.map((child, i) => {
//     return (
//       <AnimatedSlideInComponent key={i} delay={i * 0.2}>
//         {child}
//       </AnimatedSlideInComponent>
//     );
//   });

//   return <>{childrenWithAnimation}</>;
// }

// function AnimatedSlideInComponent({ delay = 0, children }) {
//   const [ref, inView] = useInView({ threshold: 0.1 });

//   const y = { target: 0, initial: 10 };

//   return (
//     <div ref={ref}>
//       <motion.section
//         initial={{ y: y.initial, opacity: 0.1 }}
//         animate={{
//           y: inView ? y.target : y.initial,
//           opacity: inView ? 1 : 0,
//         }}
//         transition={{ duration: 0.6, delay }}
//       >
//         {children}
//       </motion.section>
//     </div>
//   );
// }

export default props => (
  <StyledDiv className="App">
    <AnimationReveal {...props} />
  </StyledDiv>
);
