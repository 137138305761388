import "./index.css";
import { useTranslation } from "react-i18next";
import { message } from "antd";

const News = (props) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const handleLearnMore = (url) => {
    window.open(url,"_blank")
  };

  return (
    <div className="newsList">
      {contextHolder}
      {props.data.map((item, index) => (
        <div className="news" key={index}>
          <img src={item.img} alt="" />
          <div className="news_details">
            <div className="news_time">{item.date}</div>
            <div className="news_info">{item.msg}</div>
            <button onClick={() => handleLearnMore(item.link)}>
              {t("LearnMore")} {">"}{" "}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default News;
