const TRANSLATION_ZH = {
    "EncoSmart": "享刻智能",

    // header 头部
    "AboutEnco": "关于享刻",
    "Product": "了解产品",
    "PressReleases": "新闻发布",
    "CoreTechnology": "核心技术",
    "CleaningTools": "清洁工具",
    "ContactUs": "联系我们",

    // footer 尾部
    "BusinessCooperation": "商务合作",
    "Recruitment": "人才招聘",
    "TeaWorkstation": "茶饮工作站",
    "R&Dteam": "研发团队",
    "EncoInformation": "享刻资讯",
    "NewsCenter": "新闻中心",
    "CommonProblem": "常见问题",
    "InvestorCenter": "投资者中心",
    "CopyrightNotice": "版权所有 © 2023 享刻智能（技术）有限公司保留所有权利",
    "RecordKeeping": "京ICP备2022017723号-1",

    //首页
    "LearnMore": "了解更多",
    "CarouselDesc": "享刻智能2022年5月成立于北京，是全国前列的以机器人多模态感知技术为核心，面向“泛生活服务”场景提供高效、智能的全栈式机器人产品及自动化解决方案的初创公司。",
    "AboutProduct": "关于产品",
    "AboutProductDesc": "面向连锁快餐厅的全自动炸制机器人工作站",
    "CoreTechnologyDesc": "以机器人定制化执行结构设计，配合三维感知定位与任务规划系统，结合传统商用自动化设备的多模态机器人自动化系统集成是产品的核心技术点，也是核心技术壁垒。",
    "AboutEncoDesc": "享刻智能是一家机器人及智能化技术中国创业公司，聚焦以餐饮行业为代表的泛生活服务场景。公司通过研发以多模态感知模型与具身智能平台为核心的软、硬件一体化产品，面向全球餐饮行业等连锁品牌，提供最具性价比的无人化降本增效解决方案。",
    "Founder": "享刻智能 创始人&CEO",
    "FounderDesc": "享刻智能创始人兼CEO、教授级高级工程师、中国计算机学会人机交互专业委员会执行委员、北航杭州创新研究院高级研究员（兼）、清华企业家协会会员，原“速感科技”创始人兼CEO。个人获得70余项中国及海外发明专利，发表8篇专业相关学术论文，入选“高聚工程”高端领军人才，中关村创新合伙人，北京市青年骨干人才；获得国家工信部“创新创业”特等奖，中国互联网+创业大赛全国亚军，福布斯“亚洲30位30岁以下改变世界的青年精英”等荣誉。2021年，陈震向北京航空航天大学捐赠并设立“陈震创新创业奖学金”用于支持及鼓励母校创新创业人才培养工作。",
    "LatestNews": "最新动态",
    "PastNews": "往期新闻",
    "News1Info": "2023享刻智能夏季招聘｜诚聘英才，邀你一起共创AI+机器人",
    "News2Info": "喜报｜享刻智能陈震获2023世界人工智能大会WAIC产业创新十大杰出人物提名",
    "News3Info": "主题分享 | 享刻智能陈震：未来十年，科技创变者，应立足中国，向世界传递中国价值观",
    "VideoDesc": "解锁机器人厨房更多可能性",
    "VideoError": "您的浏览器不支持当前视频播放。",
    "Investors": "投资方们",

    // Lava产品页
    "LavaHeaderDescInfo": "享刻智能 LAVA 机器人是一款基于炸制场景技术创新的智能炸制品解决方案。",
    "LavaHeaderDescDetail": "该产品融合了自研的视觉模组和定制的垂直领域机械臂，通过图像处理、运动规划和自动化控制等先进技术，实现高效、智能、精准的炸制品生产能力。视觉模组能够高精度识别食材形态和状态，定制的机械臂能够高效完成取料、烹炸和出料等操作，实现全自动化生产。先进的图像处理算法和智能运动规划系统保障机器人灵活高效地控制动作，自动化控制系统确保全程智能化生产，降低人为干预，确保食品质量和安全。通过实时记录生产数据和质量指标，机器人提供数据管理和追溯能力，助力企业进行精细化运营管理。享刻智能 LAVA 机器人的解决方案为餐饮行业带来技术突破和可持续发展的机遇。",
    "LavaScenarioTitle": "应用场景",
    "LavaScenarioDesc": "快餐连锁店、自助餐厅、大型食堂等西式炸制餐饮场所",
    "LavaScenario1": "快餐连锁店",
    "LavaScenario2": "自助餐厅",
    "LavaScenario3": "大型食堂",
    "LavaProductTitle": "产品介绍",
    "LavaProductDesc": "面向连锁快餐厅的全自动炸制机器人工作站",
    "LavaProductEncoJoint25Desc": "EncoJoint 25是一款一体化、高性能、低能耗的机器人核心关节模组，内部集成了驱动器，力矩电机，光编码器，摩擦式抱闸，谐波减速机，搭配高度一体化的精密结构设计。关节模组可以与其他模组或系统协同工作，实现多轴运动控制。通过精确的位置同步和协调，可以实现复杂的多轴运动任务，提高生产效率和系统灵活性。",
    "LavaProductEncoArmDesc": "EncoArm是一款专为厨房垂直领域定制的协作机器人。它的设计目的是通过协作式自动化来解放厨房中繁琐低效的重复劳动，并更好地适应工作环境。EncoArm具备软硬件直通的灵活性和自主性，可以与其他设备或系统进行无缝集成和交互。EncoArm不仅节省了空间，而且降低了部署机器人的成本。它的智能化和高度可编程性使其能够适应各种厨房任务，如食材切割、搅拌、搬运等。EncoArm的协作功能可使其与人类操作员共同完成任务，提高生产效率和安全性。",
    "LavaProductEncobotLavaDesc1": "Encobot LAVA 是一款智能炸制品解决方案，以其低成本和安全灵活的特点引人关注。采用先进的Al算法和空间感知技术，配备自主规划能力，实现高效食材识别和精准操作。",
    "LavaProductEncobotLavaDesc2": "LAVA的订单管理系统支持多单调度和时间优化，通过一键操作轻松处理多个订单，提高生产效率，缓解用餐高峰压力。自动化控制功能确保系统的稳定性和可靠性，同时简化维护流程，为餐饮食堂带来智能化、高效化的炸制品生产能力。其烹饪系统利用多模态数据和云平台，优化炸制过程，提高产品质量和口感。而且，LAVA具备可扩展性，满足不同食堂规模和需求的应用场景。",
    "LavaProductEncobotLavaDesc3": "其优势在于低成本、安全灵活，借助Al算法、空间感知、自主规划和食材识别等技术，实现高效自动化生产，为食堂提供稳定可靠的炸制品解决方案。同时，订单管理系统的多单调度和时间优化功能，以及烹饪系统的多模态数据和云平台支持，进一步提升了生产效率和品质控制。无论是小型餐饮店还是大规模连锁餐厅，Encobot LAVA 都能为其带来智能化、高效化的炸制品生产能力，助力餐饮业不断创新，适应快速变化的市场需求。",
    "LavaFeaturesTitle": "主要功能特点",
    "LavaFeaturesDesc": "面向连锁快餐厅的全自动炸制机器人工作站",
    "LavaFeatures1Title": "高度一体化",
    "LavaFeatures1Desc": "高度一体化精密设计，性能更强",
    "LavaFeatures2Title": "快速精准制动",
    "LavaFeatures2Desc": "采用摩擦抱闸式制动，快速启停更安全",
    "LavaFeatures3Title": "高性能驱动",
    "LavaFeatures3Desc": "采用高性能驱动器，智能柔性高效驱动",
    "LavaFeatures4Title": "高效力矩电机",
    "LavaFeatures4Desc": "具有较高的转矩分辨率和低的转矩脉动，可实现更精确的位置和速度控制。",
    "LavaFeatures5Title": "高精度双编码",
    "LavaFeatures5Desc": "采用了两个独立的编码器进行位置反馈，提供更准确的绝对位置信息，从而实现全闭环精准控制。",
    "LavaProductVideo": "产品视频",
    "LavaMoreProductInfo": "更多产品信息",

    // 联系我们
    "ContactUsTitle": "联系我们获取产品信息",
    "FormName": "您的名字",
    "FormEmail": "您的邮箱",
    "FormCompany": "您的公司",
    "FormPhone": "您的电话",
    "InterestedProduct": "对哪件产品感兴趣",
    "SubmitInfo": "提交信息",
    "OurEmail": "我们的邮箱",
    "Address": "地址",
    "NoPublic": "官方公众号",

    // 核心技术
    "CoreTechTitle": "核心技术",
    "CoreTechDesc": "以机器人定制化执行结构设计，配合三维感知定位与任务规划系统，结合传统商用自动化设备的多模态机器人自动化系统集成是产品的核心技术点，也是核心技术壁垒。",

    // 研发团队
    "TeamHeaderInfoTitle": "公司愿景",
    "TeamHeaderInfoDesc": "享刻智能的愿景是成为全球机器人行业的新生代表力量，以原创核心技术和产品赋能全球市场。将在短期内依托核心团队的优势和基础，快速达到行业内顶尖机器人研发及产品化水平，中期实现万级产品量产及落地，长期成为原创核心机器人技术及产品企业的全球代表。",
    "TeamHeaderDetailItem1Title": "公司核心团队",
    "TeamHeaderDetailItem1Desc": "公司核心团队由机器人行业领军人才陈震先生领导，具有10年以上研发及创业经历，主要成员来自多家全球科技集团及厨房家电上市企业产品及研发部门，参与多项全球机器人产品及百万量级硬件量产制造。",
    "TeamHeaderDetailItem2Title": "合作机构",
    "TeamHeaderDetailItem2Desc": "公司与包括顶尖知名高校等机器人方向实验室、研究机构保持紧密合作，并已获得多家知名早期风险投资机构、上市公司数千万元融资，目前处于高速成长阶段。",
    "TeamHeaderDetailItem3Title": "享刻智能",
    "TeamHeaderDetailItem3Desc": "享刻智能是一家机器人及智能化技术创业公司，聚焦以餐饮行业为代表的泛生活服务场景。公司通过研发以多模态感知模型与具身智能平台为核心的软、硬件一体化产品，面向全球餐饮行业等连锁品牌，提供最具性价比的无人化降本增效解决方案。",
    "TeamCoreTeamTitle": "核心团队",
    "TeamCompanyMembers": "公司成员",
};

export default TRANSLATION_ZH;