import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { useTranslation } from 'react-i18next'
import { message, } from 'antd';
import { useNavigate, } from 'react-router-dom';

import aboutEnco from 'assets/images/home/aboutEnco.png'

const Container = tw.div`relative -mx-8 bg-gray-1100`;
const SingleColumn = tw.div`max-w-screen-2xl mx-auto py-20 lg:py-24`;
const Content = tw.div`mt-6`;
const Card = styled.div(props => [
  tw`mt-4 mx-8 md:flex justify-center items-start`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-2/3 lg:w-8/12 xl:w-3/4 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md ml-4 sm:ml-8 md:ml-2 lg:ml-8`;
const Subtitle = tw.div`font-semibold tracking-wide text-black text-5xl`;
const Title = tw.h4`text-3xl font-normal text-black mb-4`;
const Description = tw.p`relative mt-2 font-normal text-base text-gray-1400 leading-relaxed`;
const LearnButton = tw.button`text-xs w-32 h-10 rounded-full border border-solid border-gray-1600 absolute left-0 hover:border-primary-1200 active:border-primary-1200 focus:outline-none focus:ring focus:ring-gray-1600`;

export default () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  
  const handleLearnMore = () => {
    navigate("/aboutEnco/R&Dteam");
  }

  return (
    <Container>
      {contextHolder}
      <SingleColumn>
        <Content>
          <Card>
            <Image imageSrc={aboutEnco} alt="关于享刻" />
            <Details>
              <Subtitle>关于享刻</Subtitle>
              <Title>About EncoSmart</Title>
              <Description>{t("AboutEncoDesc")}
              <LearnButton className="aboutenco_btn" style={{bottom:"-50px"}} onClick={handleLearnMore}>{t("LearnMore")}</LearnButton>
              </Description>
            </Details>
          </Card>
        </Content>
      </SingleColumn>
    </Container>
  );
};
