// import React from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../assets/images/logo.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, message } from "antd";

import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import './light.css'

const Header = tw.header`
  flex justify-between items-center
 h-20
`;

const NavLinks = tw.div`inline-block`;

const NavLink = tw.a`
  cursor-pointer select-none
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300 text-primary-1300
  pb-1 border-b-2 border-transparent hocus:text-orange-500
`;

const defalutCss = tw`cursor-pointer text-primary-1300 select-none text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
font-semibold tracking-wide transition duration-300
pb-1 border-b-2 border-transparent hocus:text-orange-500`;

const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};
  img {
    ${tw`w-32`}
  }
`;

const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-orange-500 transition duration-300
`;
const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center text-primary-1300`}
  }
`);

const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const handleLink = () => {
    messageApi.open({
      type: "warning",
      content: "开发中，敬请期待！",
    });
  };

  const items = [
    {
      key: "1",
      label: <NavLink href="/products/Lava">LAVA</NavLink>,
    },
    {
      key: "2",
      label: <NavLink onClick={handleLink}>Encobot Tornado</NavLink>,
    },
    {
      key: "3",
      label: <NavLink onClick={handleLink}>Encobot Linn</NavLink>,
    },
  ];

  const defaultLinks = [
    <NavLinks key={1}>
      <NavLink href="/aboutEnco/R&Dteam">{t("AboutEnco")}</NavLink>
      <Dropdown
        menu={{
          items,
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space css={defalutCss}>
            {t("Product")}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
      <NavLink href="/encoInfo/newsCenter">{t("PressReleases")}</NavLink>
      <NavLink href="/aboutEnco/technology">{t("CoreTechnology")}</NavLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <LogoLink href="/home">
      <img src={logo} alt="享刻智能logo" />
    </LogoLink>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  const changeLanuage = () => {
    i18n.changeLanguage(i18n.language === "en" ? "zh" : "en");
    localStorage.setItem("currentLanguage", i18n.language);
  };

  const handleContactUs = () => {
    navigate("/business/contactUs");
  };

  return (
    <Header className={className}>
      {contextHolder}
      <DesktopNavLinks
        css={[collapseBreakpointCss.desktopNavLinks, tw`h-20 leading-none`]}
      >
        {logoLink}
        {links}
        <div css={tw`flex items-baseline`}>
          <button
            css={tw`bg-orange-400 rounded-3xl w-24 h-8 leading-8 text-white text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
            font-light tracking-wide transition duration-300
            pb-1 border-b-2 border-transparent hover:bg-orange-1000`}
            onClick={handleContactUs}
          >
            {t("ContactUs")}
          </button>
          <div
            onClick={changeLanuage}
            css={[
              tw`w-6 h-6 leading-6 flex items-center justify-center cursor-pointer select-none text-black text-xs text-center font-medium rounded-full hover:text-gray-1200 hover:border-gray-1200`,
            ]}
            style={{
              border: "1.5px solid #000",
            }}
          >
            {i18n.language === "en" ? "中" : "en"}
          </div>
        </div>
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {links}
          <div>
            <button css={defalutCss} onClick={handleContactUs}>
              {t("ContactUs")}
            </button>
            <div
              onClick={changeLanuage}
              css={[
                defalutCss,
                tw`w-8 h-8 mx-auto text-lg text-center text-primary-1300 border-primary-1300 font-semibold border-solid border-2 rounded-full`,
              ]}
            >
              {i18n.language === "en" ? "中" : "en"}
            </div>
          </div>
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" />
          ) : (
            <MenuIcon tw="w-6 h-6" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
