import React, { useEffect, useState } from "react";

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light";
import Carousel from "./carousel/index";
import AboutPro from "./aboutProduct/index";
import AboutEnco from "./aboutEnco/index";
import Footer from "components/footers/index";
import Founder from "./founder/index";
import LastestNews from "./lastestNews/index";
import Video from "components/video/index";
import Incerstor from "components/investors/index";
import Technology from "./technology/index";

import video_zh from "assets/images/products/lava/LAVA_zh.mp4";
import video_en from "assets/images/products/lava/LAVA_en.mp4";
import poster from "assets/images/products/lava/poster.png";

import { useTranslation } from "react-i18next";
import { Divider } from "antd";
export default () => {
  const { t } = useTranslation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // window.scrollTo(0, 0);

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const visible = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const currentLanguage = localStorage.getItem("currentLanguage");

  return (
      <AnimationRevealPage>
        <div style={{marginTop:"5rem"}}>
        <Carousel />
        <AboutPro />
        <Technology />
        <AboutEnco />
        <Founder />
        <LastestNews />
        <Video
          title="EncoSmart"
          desc="VideoDesc"
          video={currentLanguage == "zh" ? video_zh : video_en}
          poster={poster}
        />
        <Incerstor />
        <Footer />
        </div>
       
      </AnimationRevealPage>
  );
};
