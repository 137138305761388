import React, { useEffect, Suspense, useState } from "react";
import GlobalStyles from "styles/GlobalStyles";
import tw from "twin.macro";
import { formatDate } from "utils/formatUtils";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import axios from "axios";

import Header from "components/headers/light";
import HomePage from "views/HomePage/index"; //首页
// import Lava from "views/AboutProducts/Lava"; //LAVA产品详情页
// import ContactUs from "views/Business/ContactUs/index"; // 联系我们
// import RDTeam from "views/AboutEnco/R&Dteam/index"; // 研发团队
// import Technology from "views/AboutEnco/Technology/index"; // 核心技术
// import NewsCenter from "views/EncoInformation/NewsCenter/index"; //新闻中心

// 路由懒加载
const Lava = React.lazy(() => import("views/AboutProducts/Lava"));
const ContactUs = React.lazy(() => import("views/Business/ContactUs/index"));
const RDTeam = React.lazy(() => import("views/AboutEnco/R&Dteam/index"));
const Technology = React.lazy(() => import("views/AboutEnco/Technology/index"));
const NewsCenter = React.lazy(() =>
  import("views/EncoInformation/NewsCenter/index")
);

export default function App() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const header = document.getElementsByClassName("header-light");
    const visible = currentScrollPos <= header[0].offsetHeight || prevScrollPos >= currentScrollPos;
    // console.log(currentScrollPos,prevScrollPos);
    setPrevScrollPos(currentScrollPos);
    setVisible(visible);
  };

  //获取访客ip并发送给后台
  const handleSendBuried = () => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        const userIP = data.ip;
        // const currentTime = formatDate(new Date(), "YYYY-MM-DD HH:mm:ss");
        const currentTime = new Date();
        axios
          .post("/server/sendDataBuried", { userIP, currentTime })
          .then((response) => {
            if (response.status === 200 && response.data.code === 0) {
              console.log("数据埋点发送成功");
            } else {
              console.log("数据埋点发送失败", response);
            }
          })
          .catch((err) => {
            console.log("数据埋点发送失败", err);
          });
      })
      .catch((error) => {
        console.error("获取IP地址时出错:", error);
      });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    handleSendBuried();
  }, [])

  return (
    <>
      <GlobalStyles />

      <Router css={tw`py-0`}>
        <Header className={visible ? "header-light" : "header-light hidden"} />

        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<HomePage />} /> //首页
            <Route path="/products/Lava" element={<Lava />} /> //LAVA产品详情页
            <Route path="/business/contactUs" element={<ContactUs />} />{" "}
            //联系我们
            <Route path="/aboutEnco/R&Dteam" element={<RDTeam />} /> //研发团队
            <Route path="/aboutEnco/technology" element={<Technology />} />{" "}
            //核心技术
            <Route path="/encoInfo/newsCenter" element={<NewsCenter />} />{" "}
            //新闻中心
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}
