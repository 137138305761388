import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TRANSLATION_EN from './en.js';
import TRANSLATION_ZH from './zh.js';
 

const currentLanguage = localStorage.getItem('currentLanguage') || 'zh';

const resources = {
	en: {
		translation: TRANSLATION_EN,
	},
	zh: {
		translation: TRANSLATION_ZH,
	},
};
 
i18n.use(initReactI18next).init({
	resources,
	// 默认语言  zh/en  中文/英文
	lng: currentLanguage,
    fallbackLng: 'zh',
	interpolation: {
		escapeValue: false,
	},
});
 
export default i18n;