import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";

import LogoImage from "assets/images/logowhite.png";
import qrcode from "assets/images/Nopublic.png";

import { useTranslation } from 'react-i18next'
import { message, } from 'antd';
import { useNavigate, } from 'react-router-dom';

const Container = tw.div`relative bg-primary-900 -mx-8 -mb-8 px-8`;
const Content = tw.div`max-w-screen-2xl mx-auto pt-16 pb-8`;
const FiveColumns = tw.div`flex flex-wrap justify-between items-baseline`;

const Column = tw.div`w-1/2 md:w-1/6 mb-8 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center lg:pl-12 md:text-left mb-16 lg:mb-0 w-full lg:w-2/6`;

const ColumnHeading = tw.h5`font-bold uppercase text-white`;

const LinkList = tw.ul`mt-6 text-sm font-light text-white`;
const LinkListItem = tw.li`py-4`;
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const LogoImg = tw.img`w-40`;

const CompanyAddress = tw.p`text-gray-900 my-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const QrcodeContainer = tw.div`flex items-center justify-center -mb-24 lg:justify-start`;
const Qrcode = tw.img`scale-50 origin-top lg:origin-top-left `;

const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-xs font-normal text-center text-gray-1000`;

const Divider = tw.div`my-8 border-b-2 border-gray-800`;

export default () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const handleLink = () => {
    messageApi.open({
      type: 'warning',
      content: "开发中，尽情期待！"
    })
  }

  const handleToHome = () => {
    navigate('/home');
  }

  return (
    <Container>
      {contextHolder}
      <Content>
        <FiveColumns>
          <CompanyColumn>
            <LogoContainer>
              <LogoImg src={LogoImage} alt="享刻智能logo" onClick={handleToHome} />
            </LogoContainer>
            <CompanyAddress>
            {t("BusinessCooperation")}：bd@encosmart.com <br />
            {t("Recruitment")}：hr@encosmart.com
            </CompanyAddress>
            <QrcodeContainer>
              <Qrcode src={qrcode} alt="享刻智能公众号二维码" />
            </QrcodeContainer>
          </CompanyColumn>
          <Column>
            <ColumnHeading>{t("AboutProduct")}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/products/Lava">LAVA</Link>
              </LinkListItem>
              <LinkListItem>
                <Link onClick={handleLink}>Encobot Tornado</Link>
              </LinkListItem>
              <LinkListItem>
                <Link onClick={handleLink}>Encobot Linn</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>{t("AboutEnco")}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/aboutEnco/R&Dteam">{t("R&Dteam")}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/aboutEnco/technology">{t("CoreTechnology")}</Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="/business/contactUs">{t("ContactUs")}</Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>{t("EncoInformation")}</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/encoInfo/newsCenter">{t("NewsCenter")}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link onClick={handleLink}>{t("Recruitment")}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link onClick={handleLink}>{t("CommonProblem")}</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>{t("BusinessCooperation")}</ColumnHeading>
            <LinkList>
              {/* <LinkListItem>
                <Link onClick={handleLink}>{t("InvestorCenter")}</Link>
              </LinkListItem> */}
              <LinkListItem>
                <Link href="/business/contactUs">{t("ContactUs")}</Link>
              </LinkListItem>
            </LinkList>
          </Column>
        </FiveColumns>
        <Divider />
        <CopyrightAndCompanyInfoRow>
          {t("CopyrightNotice")}
        </CopyrightAndCompanyInfoRow>
        <CopyrightAndCompanyInfoRow>
          {t("RecordKeeping")}
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
