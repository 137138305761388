// import react from "react";
import tech from 'assets/images/home/tech.png'
import "./index.css";
import { useTranslation } from 'react-i18next'
import { message } from 'antd';
import { useNavigate, } from 'react-router-dom';

export default () => {
  const { t } = useTranslation();
  const [ messageApi, contextHolder ] = message.useMessage();
  const navigate = useNavigate();

  const handleLearnMore = () => {
    navigate('/aboutEnco/technology');
  }

  return (
    <div className="technology_container">
      {contextHolder}
      <div className="technology_wrapper">
        <img src={tech} alt="享刻智能 核心技术" />
        <div className="technology_desc">
            <div className="title">{t("CoreTechnology")}</div>
            <div className="detail">{t("CoreTechnologyDesc")}</div>
            <button onClick={handleLearnMore}>{t("LearnMore")}</button>
        </div>
      </div>
    </div>
  );
};