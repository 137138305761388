// import react from "react";

import CEO from 'assets/images/founder.png'

import "./index.css";
import { useTranslation } from 'react-i18next'

export default () => {
  const { t } = useTranslation();

  return (
    <div className="founder_container">
      <div className="content">
        <div className="title_wrapper">
          <div className="title">{t("Founder")}</div>
          <div className="name">陈震 Evan Chen</div>
        </div>
        <div className="ceo">
            <img src={CEO} alt="享刻智能创始人" />
            <div className="ceo_detail">
                <span className="icon"></span>
                <div className="info">{t("FounderDesc")}</div>
            </div>
        </div>

      </div>
    </div>
  );
};
