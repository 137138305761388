// import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import lava from "assets/images/home/lava.png";
import lavaLift from "assets/images/home/lava lift.png";
import EncobotTornado from 'assets/images/products/lava/EncobotTornado.png';
import EncobotLinn from 'assets/images/products/lava/EncobotLinn.png';

import "./index.css";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { useNavigate } from 'react-router-dom';

const HeadingTitle = tw.h2`text-4xl sm:text-5xl mt-12 text-black font-semibold text-center`;
const Container = tw.div`relative -mx-8 bg-gray-1100`;
const Content = tw.div`max-w-screen-2xl py-20 mx-auto lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`my-12 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center mx-8`;
const HeadingDescription = tw.p`text-2xl sm:text-3xl mt-4 font-normal text-gray-600 text-center`;

const Card = tw.div`lg:max-w-xs xl:max-w-sm mx-auto`;
const Image = tw.img`h-80 lg:h-96 w-full`;
const BtnGroup = tw.div`h-10 w-full mt-8 text-center`;
const Button = tw.button`w-24 h-full font-normal text-sm rounded-full border border-solid mr-8`;
const LinkButton = tw.button`w-24 h-full font-normal text-sm rounded-full border border-solid border-white bg-orange-400 text-white`;

export default () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const handleLearnMore = (url) => {
    if(undefined == url){
      messageApi.open({
        type: "warning",
        content: "开发中，敬请期待！",
      });
    }
    navigate(url);

  };

  const handleContactUs = () => {
    navigate("/business/contactUs")
  };

  return (
    <Container>
      {contextHolder}
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>{t("AboutProduct")}</HeadingTitle>
          <HeadingDescription>{t("AboutProductDesc")}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          <Column key={1}>
            <Card>
              <div className="image-wrapper">
                <Image src={lava} alt="享刻智能产品 Lava" />
                <div className="mask"></div>
                <span className="desc">LAVA</span>
              </div>

              <BtnGroup>
                <Button
                  className="aboutPro_btn"
                  onClick={() => handleLearnMore("/products/lava")}
                >
                  {t("LearnMore")}
                </Button>
                <LinkButton className="aboutPro_lbtn" onClick={()=> handleContactUs()}>
                  {t("ContactUs")}
                </LinkButton>
              </BtnGroup>
            </Card>
          </Column>
          <Column key={2}>
            <Card>
              <div className="image-wrapper">
                <Image src={EncobotTornado} alt="享刻智能产品 Encobot Tornado" />
                <div className="mask"></div>
                <span className="desc">Encobot Tornado</span>
              </div>

              <BtnGroup>
                <Button
                  className="aboutPro_btn"
                  onClick={() => handleLearnMore()}
                >
                  {t("LearnMore")}
                </Button>
                <LinkButton className="aboutPro_lbtn" onClick={()=> handleContactUs()}>
                  {t("ContactUs")}
                </LinkButton>
              </BtnGroup>
            </Card>
          </Column>
          <Column key={3}>
            <Card>
              <div className="image-wrapper">
                <Image src={EncobotLinn} alt="享刻智能产品 Encobot Linn" />
                <div className="mask"></div>
                <p className="desc">Encobot Linn</p>
              </div>
              <BtnGroup>
                <Button
                  className="aboutPro_btn"
                  onClick={() => handleLearnMore()}
                >
                  {t("LearnMore")}
                </Button>
                <LinkButton className="aboutPro_lbtn" onClick={()=> handleContactUs()}>
                  {t("ContactUs")}
                </LinkButton>
              </BtnGroup>
            </Card>
          </Column>
        </ThreeColumn>
      </Content>
    </Container>
  );
};
