import "./index.css";
import { useTranslation } from 'react-i18next'

const Video = (props) => {
  const { t } = useTranslation();

  return (
    <div className="video_container">
      <div className="content">
        <div className="wrapper">
          <div className="title">{t(props.title)}</div>
          <div className="video_desc">{t(props.desc)}</div>
            <video className="video" src={props.video} controls poster={props.poster}>
            {t("VideoError")}
            </video>
            {/* <div className="video">
              <iframe src="//player.bilibili.com/player.html?isOutside=true&aid=112533562198699&bvid=BV1dUTkeRETC&cid=1566038327&p=1"  scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>
            </div> */}
        </div>
      </div>
    </div>
  );
};

export default Video;
